@keyframes k-show-feedback {
  0% {
    opacity: 0;
    margin-top: 100px;
  }
  100% {
    opacity: 1;
    margin-top: 40px;
  }
}

#e-feedback-form {
  height: 100%;
  color: #9e9e9e;
}

#e-feedback-form *{
  box-sizing: border-box;
  outline: 0 !important;
}

#e-feedback-form .wrapperr {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#e-feedback-form .center {
  margin: 0 auto;
}

#e-feedback-form .card {
  width: 100%; 
  max-width: 700px;
  padding: 30px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 10px 5px rgba(0,0,0,0.2);
  animation: k-show-feedback 0.2s forwards;
}

#e-feedback-form .input-form {
  margin-bottom: 24px;
}

#e-feedback-form .input-form h5 { 
  width: 100%;
  color: #9e9e9e;
  margin-bottom: 4px;
}

#e-feedback-form input {
  width: 100%;
  height: 35px;
  font-size: 16px;
  color: #4a5568;
  padding: 6px 12px;
  border-radius: 5px; 
  border: 1px solid #EDF2F7;
  background-color: #EDF2F7;
  transition: all ease-in 0.1s;
}

#e-feedback-form input:focus {
  border: 1px solid #EDF2F7;
  background-color: transparent;
}

#e-feedback-form .inline {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

#e-feedback-form .inline button{
  margin-left: 12px;
  border: none;
  height: 28px;
  padding: 6px;
  color: #fefefe;
  font-size: 12px;
  border-radius: 4px;
  background-color: rgb(44, 118, 197);
  transition: all ease-in 0.2s;
}

#e-feedback-form .inline button:hover {
  cursor: pointer;
  background-color: #2B6BB0;
}

#e-feedback-form .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

#e-feedback-form .logo img {
  transform: rotate(15deg);
}

#e-feedback-form .logo h1 {
  margin-left: -12px;
  font-size: 3em;
  color: #C41A2B;
}

#e-feedback-form .table {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding-top: 10px;
}

#e-feedback-form .table .list-card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 8px 0;
  background-color: #EDF2F7;
  box-shadow: 0 2px 3px 1px rgba(0,0,0,0.2);
  transition: all ease-in 0.2s;
  overflow: hidden;
}

#e-feedback-form .table .list-card:hover {
  box-shadow: 0 2px 6px 1px rgba(0,0,0,0.2);
}

#e-feedback-form .table .list-card .instructions {
  padding: 16px 18px;
}

#e-feedback-form .table .list-card .id {
  color: black;
}

#e-feedback-form .table .list-card .footer {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  padding: 12px 18px;
}

#e-feedback-form .table .list-card .footer .action a {
  padding: 6px 12px;
  border-radius: 4px;
  background-color: #cacaca;
}

#e-feedback-form .table .list-card .footer .action a:first-child {
  margin-right: 8px;
}

#e-feedback-form .submit {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  height: 38px; 
  width: 100%;
  color: #fff;
  font-size: 16px;
  background-color: #345cec;
  transition: all ease-in 0.2s;
}

#e-feedback-form .submit:hover {
  cursor: pointer;
  background-color: #284ac5;
}

@media (min-width: 320px) and (max-width: 640px) {
  #feedback-submit-button {
    padding: 0;
  }

  #e-feedback-form .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    overflow-y: auto; 
  }
}

/* End */

.steps-action { 
  display: flex;
  justify-content: flex-end;
}

.steps-content {
  margin: 30px 0;
}

.ant-checkbox-group-item {
  display: block;
  margin: 0 0 4px 0;
}

/* CARD */
.d-card {
  width: 300px;
  height: 250px;
  padding: 12px 20px;
  margin-bottom: 16px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0 2px 4px 3px rgba(0,0,0,0.05);
  transition: all 150ms ease-in;
}

.d-card:hover {
  box-shadow: 0 2px 6px 4px rgba(0,0,0,0.075);
}

.d-card .d-close {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 30px;
  height: 30px;
  border-radius: 200px;
  background-color: #ececec;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
}

.d-card .d-header {
  font-size: 1.25em;
  margin-bottom: 4px;
}

.d-card .d-sub-header {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1em;
  margin-bottom: 20px;
}

.d-card .d-action {
  text-align: center;
}

.d-card .d-link-point {
  width: fit-content;
  font-size: 14px;
  cursor: pointer;
  color: #2847EC;
}

.d-card .d-accept {
  width: 100% !important;
  margin-bottom: 6px !important;
  height: 32px !important;
} 

/* CARD END */

.menu-box {
   transition: all 200ms ease-in;
}

.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
	box-shadow: 0 .2rem .5rem rgba(0,0,0,.075) !important;
	margin: 20px 0 !important;
}

.point {
   cursor: pointer;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
	padding-bottom: 0 !important;
}

.filter-label[for='select-filter-column-Status'] {
	margin-bottom: 0;
}

.m-input-moment .options {
	display: none;
}

.m-input-moment .btn-save {
	margin-top: -30px;
    width: 100%;
    height: 31px;
    padding: 5px 0;
}

.m-input-moment {
	position: absolute !important;
    background-color: #fff;
	z-index: 1;
	-webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
			transform: translateY(-600px) rotateX(-30deg) scale(0);
	-webkit-transform-origin: 50% 100%;
			transform-origin: 50% 100%;
	opacity: 0;
}

#assign-self span,
#unassign-self span {
  margin-top: -6px
}

.fab-add {
	position: fixed !important;
	bottom: 15px;
	right: 20px;
	width: 56px !important;
    height: 56px !important;
    padding: 0 0 0 13px !important;
    font-size: 0.875rem !important;
    min-width: 0 !important;
    box-sizing: border-box !important;
	min-height: 36px !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.75 !important;
    border-radius: 50% !important;
	letter-spacing: 0.02857em !important;
}

#feedback-title {
	vertical-align: top;
}

#instruction {
	height: 130px !important;
}

#pre-instruction, .pre-instructions {
	overflow-x: hidden;
	white-space: pre-wrap;
	word-wrap: break-word;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
	text-align: left;
}

#empty-background {
	background-color: #F1F0EF;
	padding: 20px;
	border-radius: 10px;
}

#edit-assign-users .MuiSvgIcon-root.MuiChip-deleteIcon {
	display: none;
}

.versions-table tbody tr td:first-child {
	width: 37px;
	padding-left: 12px;
}

.points-table tbody tr td:first-child {
	padding-left: 12px;
}

.points-table tbody tr td:nth-child(2) {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filepond--file-action-button.filepond--action-process-item {
	visibility: hidden;
}

.react-photo-gallery--gallery {
	overflow-x: initial;
}

.react-photo-gallery--gallery div {
	flex-flow: nowrap !important;
}

#gallery-scroll .scroll-content {
	padding-bottom: 0;
}

.react-photo-gallery--gallery img {
    width: 80px !important;
    height: 60px;
	object-fit: contain;
	transition: all 200ms ease;
	margin-right: 10px !important;
	transform: scale(1)
}

.react-photo-gallery--gallery img:hover {
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
	transform: scale(1.1)
}


/* .points-table tbody tr td:first-child,
.points-table thead tr th:first-child  {
	padding-left: .75rem;
} */

.points-table thead tr th:nth-child(2) label {
	margin-left: 10px;
}

table thead th:focus {
	outline: none;
}

.points-table {
	margin-top: -40px;	
	/* margin-left: 22px; */
}

.versions-table {
	margin-top: -20px;	
}

#edit-feedback-title {
	margin-left: 5px;
	margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    width: 10px;
}

#edit-feedback-title .MuiSvgIcon-root {
	font-size: 1.2rem;
}

#edit-feedback-title:hover {
	background-color: transparent;
}

#dateme {
	border-color: #CED4DA;
}

#instruction {
	font-size: 0.875rem;
}

#dateme:hover {
	border-color: #6C757D;
}

.MuiButton-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.26) !important
	;
}

.warning-box {
   border: 1px solid red;
   border-radius: 6px;
}

.point-input {
   border: none;
   outline: none;
   font-size: 12px;
   width: 100%;
}

.point-form {
   border-bottom: 1px solid #a31423;
}

.icon {
   cursor: pointer;
   color: rgb(255, 58, 58);
}

.icon:hover {
   transform: scale(1.1);
}

.view-box-form {
   height: 68vh;
   overflow-y: auto;
}

.header-box {
   z-index: 1;
   background-color: white;
}

.date-picker {
   height: calc(1.5em + .5rem + 2px);
   padding: .25rem .5rem;
   font-size: .875rem;
   line-height: 1.5;
   border: 1px solid #ced4da;
   border-radius: .2rem;
   width: 100%;
}

.react-datepicker__input-container {
   width: 100%;
}  

.react-datepicker-wrapper {
   width: 100%;
}  


/* .list-group-item:hover {

   transform: scale(1.01);
   box-shadow: 0 0.2rem 1rem rgba(0,0,0,.15);
   z-index: 1;
} */

.contract-point{
   padding: 5px;
   border: 1px solid #c1c1c2;
   border-radius: 4px;
   background-color: white;
}

div[class^="col-"] {
   transition: all 200ms ease-in-out;
}

#feedback-points-table tbody tr:hover {
	cursor: pointer;
}

/* COMMENT STYLE */
.left-group, .right-group {
	display: inline-block;
	height: 100%;
}

.left-group {
	width: 8%;
}

.right-group {
	width: 92%;
}

.comment-section {
	border-radius: 5px;
	border: 1px #e3e3e3 solid;
	width: 100%;
	position: relative;
	/* max-height: 300px;
	overflow-y: scroll; */
}

.comment-container {
	width: 100%;
	padding: 5px 7px;
	margin-bottom: 7px;
	overflow: auto;
}

.comment-profile-photo {
	border-radius: 100px;
	background-color: #303F9F;
	box-shadow: 0 1px 2.5px rgba(0,0,0,0.3);
	display: inline-block;
	height: 35px;
	width: 35px;
	color: #fff;
	padding: 8px 0;
	text-align: center;
	margin-right: 10px;
	vertical-align: bottom;
}

.comment-group {
	display: inline-block;
	position: relative;
	vertical-align: bottom;
	width: 100%;
}

.comment-name {
	display: block;
	margin-bottom: 5px;
	margin-left: 10px;
}

.comment {
	border-radius: 15px;
	background-color: #E9EBEE;
	color: #000;
	padding: 3.5px 11px;
	display: inline-block;
  max-width: 80%;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2)
}

.comment pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  overflow: hidden;
  margin-bottom: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.comment-c {
	width: 100%;
	height: 25px;
  display: flex;
  height: inherit;
  align-items: center;
	overflow-y: inherit;
  transition: 150ms all;
}

.delete-comment {
  opacity: 0;
  width: 0;
  transition: 150ms all;
}

.delete-comment:hover {
  color: red;
  cursor: pointer;
}

.comment-c.right:hover .delete-comment {
	opacity: 1;
  width: 14px;
  margin-right: 5px;
}

.comment-c.right {
	display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  /* width: fit-content; */
}

.comment-time {
	margin-left: 10px;
	margin-right: 10px;
  display: inline-block;
  word-break: break-word;
  vertical-align: bottom;
}

/* .comment-group.right .comment-time time{
	width: 10%;
    word-break: break-word;
    display: inline-block;
    text-align: right;
} */

#feedback-points-table .input-group-text {
	height: 31px;
}

#attachmentss .scroll-content {
	padding-bottom: 0;
}

.clear-deadline:hover {
	cursor: pointer;
}

#filepond-upload .filepond--drop-label, 
#filepond-upload .filepond--file-action-button.filepond--action-remove-item
 {
	/* display: none; */
}

#more-options button {
	color: rgb(66, 66, 66);
	border: none;
	background-color: transparent !important;
}

#more-options {
	margin: -15px;
	padding: 0;
	top: 0; right: 0;
	position: absolute;
}

#more-options .dropdown-menu.show {
	z-index: 1;
	left: -30px !important;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

#hoverable-title {
	color: rgb(60, 87, 236);
	text-decoration: underline;
}

#hoverable-title:hover {
	cursor: pointer;
	color: #2847EC;
}

#feedback-points-table th{
	padding-left: 0.50rem;
	padding-right: 0.50rem;
}

.fileupload .filepond--file {
	transform: scale(1);
	transition: 250ms all;
}

.fileupload .filepond--file:hover {
	cursor: pointer;
	background-color: rgb(121, 116, 114);
	transform: scale(1.015);
}

.comments.right .comment-time{
	text-align: right;
	max-width: 400px;
    width: auto; 
    min-width: 80px;
}

.comments {
	width: 100%;
}

.right {
	float: right
}

.your {
	background-color: #2847EC;
	color: #fff;
	margin-right: 5px;
	float: right;
}

.comment-bottom {
	padding: 8px;
	height: auto;
	border-top: 1px #ececec solid;
	padding-top: 8px;
	/* background-color: #fff; */
}

#comment-input {
	border-radius: 20px 0 0 20px;
  font-size: 14px;
  border-right: none;
  resize: none;
  border: none;
  /* height: 31px; */
  /* min-height: 31px; */
  max-height: 100px; 
  padding: 5px 10px;
  display:block;
}

.comment-bottom .input-group {
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

#comment-input:focus {
	outline: none;
	box-shadow:none !important;
	/* border: 1px solid #ccc; */
	/* border-right: 0 solid #fff; */
}

input:focus, textarea:focus, select:focus{
	outline: none;
}

.comment-bottom .ant-mentions {
  border-radius: 20px 0 0 20px;
  border: 0;
  margin-top: 1px;
  margin-bottom: 0;
  width: auto;
  flex-grow: 1;
}

.SortableList {
  position: relative;
  z-index: 0;
  max-height: 500px;
  background-color: #F3F3F3;
  border: 1px solid #EFEFEF;
  border-radius: 3px;
  outline: none;
  width: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #E0E0E0;
  list-style: none;
  padding: 0;
}

.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #FFF;
  border-bottom: 1px solid #EFEFEF;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
  z-index: 9999999;
}

.SortableHelper {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize !important;
  /* z-index: 999; */
}


/* .comment-bottom .input-group {
  border: none;
  margin-bottom: 0;
} */

.comment-bottom .input-group-text {
	height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
	line-height: 1.5;
	/* border-radius: 0 100px 100px 0;	 */
	/* background-color: #fff; */
	border-left: 0;
}

.comment-bottom span {
	padding-right: 10px !important;
	transition: 150ms ease;
}

.send-comment:hover {
	background-color: rgb(20, 51, 223);
	cursor: pointer;
}

.send-comment {
  color: #fff;
  background-color: #2847EC;
  box-shadow: 0 2px 6px rgba(0,0,0,0.35);
}

.comment-here {
  border-radius: 5px 20px 20px 5px !important;
  border: none;
  margin: 5px;
  height: auto !important;
  transition: all 300ms !important;
}

.comment-box .scroll-content {
	padding-bottom: 20px;
}

.circle {
	display: inline-block;
	background-color: #000;
	height: 5px;
	width: 5px;
	border-radius: 100px;
	margin: 0 1.5px;
  }
    
  #ball-1{
	animation-name: bounce;
	animation-delay: 1s;
	animation-duration: 1.2s;
	animation-iteration-count: infinite;
  }
  #ball-2{
	animation-name: bounce;
	animation-delay: 1.1s;
	animation-duration: 1.2s;
	animation-iteration-count: infinite;
  }
  #ball-3{
	animation-name: bounce;
	animation-delay: 1.2s;
	animation-duration: 1.2s;
	animation-iteration-count: infinite;
  }
  
  @keyframes bounce {
	0% {
	  transform: translateY(0);
	}
	40% {
	  transform: translateY(6px);
	}
	60% {
	  transform: translateY(-8px);
	}
	80%{
	  transform: translateY(0);
	}
  }