* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

h1, h5, h2, h3 {

  margin: 0;
}

p {

  font-size: 80%
}

button[data-tour-elem="right-arrow"] {
  position: absolute;
  right: 20px;
}

div[data-tour-elem="controls"] {
  height: 32px;
}

.alert .close {
	padding: .6rem 1.25rem;
}

.primary-color-bg { 
	background-color: rgb(255, 58, 58);
	border-color: rgb(255, 58, 58);
}

.is-register {
  background-color: #fff;
}

.primary-color-text {
	color: rgb(255, 58, 58);
}

.btn-outline-exact {
	border-color: rgb(255, 58, 58);
	color: rgb(255, 58, 58);
	background-color: transparent;
}

.btn-exactplace {
	border-color: rgb(255, 58, 58);
	color: #fff;
	background-color: rgb(255, 58, 58);
}

.points:hover {
	cursor: pointer;
}

.sidebar .nav-item:hover:last-child {
	cursor: initial;
}

.sidebar .nav-item {
	width: 153px;
	transition: all 200ms ease;
}

.link-active {
	color: rgb(255, 58, 58) !important;
    border-left: rgba(255, 58, 58, 1) solid 3px !important;
}

.link-sidebar {
	transition: border-left 200ms ease-out;
	border-left: rgba(255, 58, 58, 0) solid 3px ;
}

.link-sidebar:hover {
     border-left: rgba(255, 58, 58, 1) solid 3px !important;
}

.MuiSnackbarContent-root{
	background-color: #43A047 !important;
}

.MuiPaper-elevation4 {
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

.MuiChip-sizeSmall {
	height: 24px !important;
}

.MuiChip-sizeSmall .MuiChip-avatar {
	width: 18px;
  height: 18px;
  font-size: 0.625rem;
  margin-left: 4px;
  margin-right: -4px;
}

.MuiChip-sizeSmall .MuiChip-deleteIcon {
	font-size: 1rem;
}

.MuiChip-root.active {
  box-shadow: 0px 2px 5px rgba(33, 160, 199, 0.212) !important;
  background-color: #E8F5F9;
  color: #219FC7;
}

.save-fab {
  background: rgb(82, 196, 86) !important;
}

.save-fab:hover {
  background: #4CAF50 !important;
}

.error-text {
  color: red !important;
}

.MuiChip-root.active:hover {
  background-color: #E8F5F9;
}

.MuiChip-deletable.active:focus {
  background-color: rgb(202, 236, 247);
}

.MuiChip-sizeSmall.active .MuiChip-avatar {
  background-color: transparent;
  border: 1px solid #219FC7;
  color: #219FC7;
}

.chip-hover:hover {
	cursor: pointer;
}
.chip-unhoverable:hover {
	cursor: initial;
}

.clickable:hover {
  cursor: pointer;
}

#username, input[type="text"], input[type="password"] {
	height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.pagination.react-bootstrap-table-page-btns-ul {
	float: left;
}

#search-bar-0 {
	width: 100%;
}

#pageDropDown {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.5;
	border-radius: .2rem;
}

dd {
	margin-bottom: 0 !important;
}

dl dt:not(:first-child) {
	margin-top: .5rem;
}

.dl-item {
	display: list-item;
	margin-left: 1rem;
}

.btn-outline-exact:hover {
	color: #fff !important;
	background-color: rgb(255, 58, 58) !important;
	border-color: rgb(255, 58, 58) !important;
}

.btn-exactplace:hover {
	color: #fff !important;
	background-color: rgb(199, 45, 45) !important;
	border-color: rgb(199, 45, 45) !important;
}

body, #root {
	/* font-family: 'Poppins', sans-serif; */
	background: #fafafa;
	font-size: 14px;
	line-height: 1.4 !important; 
	height: 100vh !important;
}

a {
	color: #333;
	text-decoration: none !important;
}

.search-label {
	/* width: 100%; */
	float: right;
}

#SaveFeedback-actions button {
	color: #fff;
	background: linear-gradient(-45deg, #396afc, #2948ff);
}

#SaveFeedback-actions button:hover {
	background-color: #2B4EEC !important;
}

.MuiSpeedDial-root.MuiSpeedDial-directionLeft button:first-child {
	background-color: rgb(82, 196, 86);
}

.MuiSpeedDial-root.MuiSpeedDial-directionLeft button:first-child:hover {
	background-color: #4CAF50;
}

#feedbacklist .search-label {
	margin-top: 2px;
}

#feedbacklist .react-bootstrap-table {
	padding-top: 40px
}

#feedbacklist .dropdown-menu {
	top: initial !important;
	bottom: 100%;
}

#feedbacklist #pageDropDown::after {
	transform: rotate(180deg);
}

#feedbacklist td:not(#feedbacklist td:first-child) {
	border-right: 0;
}



.versions-table td, 
.versions-table th{
	border: 0;
}

.versions-table thead {
	display: none;
}

.versions-table td:first-child {
	padding: 7px 12px;
	width: 100px;
}

.versions-table .reset-expansion-style {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

/* .points-table th:first-child {
	width: 50.3%;
} */

.m-calendar .toolbar .next-month {
	padding-left: 2px;
}

.m-calendar .toolbar .prev-month {
	padding-right: 2px;
}

#feedbacklist #select-filter-column-Status {
	padding: .1rem .75rem;
	height: 31px;
}

#feedbacklist .filter-label {
	margin-bottom: 0;
}

.points-table thead {
	display: table-header-group !important;
}

.versions-table .react-bootstrap-table,
.versions-table .react-bootstrap-table .table {
	padding: 0 !important;
	margin-bottom: 0 !important;
}

.points-table thead {
	display: none;
}

.points-table tbody tr:hover {
	cursor: pointer;
}

#feedbacklist .table-bordered td, #feedbacklist .table-bordered th {
	border-left: 0;
	border-right: 0;
}

#main-scroll, .scroll-content {
	/* overflow: visible !important; */
	height: inherit;
}

#feedbacklist .table-bordered td {
	border-bottom: 0;
}

select { 
	height: 31px !important;
    padding: .25rem .3rem !important;
    font-size: .875rem !important;
}
	
/* .versions-table tbody tr:hover {
	background-color: transparent !important;
	cursor: none;
}*/

.MuiButtonBase-root.MuiIconButton-root[aria-label="Delete"] {
	height: 40px;
	width: 40px;
	padding-top: 7.5px;
}

.MuiFab-extended.MuiFab-sizeSmall {
	background: linear-gradient(-45deg, #396afc, #2948ff);
	padding-right: 13px !important;
	vertical-align: top;
    box-shadow: 0 2px 7px rgba(0,0,0,0.3);
	transition: all 200ms ease;
}

.MuiFab-extended.MuiFab-sizeSmall:hover {
	background: linear-gradient(-45deg, #3563ec, #2643ec);
    box-shadow: 0 3px 9px rgba(0,0,0,0.35);
}

.MuiButtonBase-root{
	text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
}

#feedbacklist tbody tr:hover > td:not(.reset-expansion-style) {
	cursor: pointer;
	color: #007BFF;
	/* background-color: #ECECEC; */
} 

#feedbacklist tbody tr td.reset-expansion-style {
	border: 0;
} 

#feedbacklist tbody tr:hover {
	cursor: initial;
}

#feedbacklist tbody .points-table tr:hover td{
	cursor: pointer !important;
	background-color: #ECECEC !important;
	color: #000;
}

#feedbacklist tbody .versions-table tr:hover td:not(.reset-expansion-style) {
	cursor: initial;
	background-color: #fff;
	color: #000;
}

.scroll-content {
	padding-bottom: 50px;
}

.btn.btn-warning.react-bs-table-del-btn  {
	color: #fff;
	background-color: #BD212D;
	border-color: #BD212D;
}

.react-bootstrap-table .row-expansion-style { 
	padding-top: 0;
}

.react-bootstrap-table {
	margin-top: 5px;
}

.btn.btn-warning.react-bs-table-del-btn:hover  {
	color: #fff;
	background-color: rgb(212, 50, 50);
	border-color: rgb(212, 50, 50);
}

.MuiButtonBase-root.MuiFab-root.MuiFab-extended.MuiFab-primary.MuiFab-sizeSmall {
	height: 31px;
}

.MuiChip-root.MuiChip-sizeSmall{
	transition: all 250ms ease;
}

.MuiChip-root.MuiChip-sizeSmall:hover {
	box-shadow: 0 1.5px 3px rgba(0,0,0,0.5);
}

/* .main-nav {
  	z-index: 1000;
} */

.sticky-me {
	position: fixed;
	top: 10px;
	left: 100px;
}

.main-box{
  	margin-left: 19%;
}

main{
  transition: all 450ms;

}

#main_background {
	object-fit: cover;
	background-size: 100px 100px;
	width: 100%;
	height: 110%;
	opacity: 0.8;
	position: fixed;
	top:-5px; right:0; left:0; bottom:0;
}

@media screen and (max-width: 1024px) {
  .main-box {

    margin-left: 0;
  }
}

.badge-postpone{
	background-color: #E83E8C;
	color: #fff;
}

.badge-open{
	background-color: #F86C6B;
	color: #fff;
}

.badge-question{
	background-color: #20A8D8;
	color: #fff;
}

.badge-testing{
	background-color: #6F42C1;
	color: #fff;
}

.badge-warning{
	color: #fff;
}

.small-link{
	color:#007bff;
	text-decoration: none;
	background-color: transparent;
}

.small-link:hover{
	color:#0056b3;
	text-decoration: underline;
	cursor: pointer;
}

.footer-login {
	/* height: 300px; */
	box-shadow: 0 -.2rem .3rem rgba(0,0,0,0.3) !important;
	border-radius: 50px 50px 0 0;
	color: #fff;
	/* background: linear-gradient(-45deg, #396afcf1, #2949ff); */
	background: linear-gradient(-45deg, #B61927, #982827);
}

.footer-content {
	width: 85%;
	margin: 50px auto 30px auto;
	display: flex;
	justify-items: center;
	justify-content: space-between;
}

.footer-content #logo-namee {
	font-size: 28px !important;
	color: #fff !important;
}

.footer-content .footer-content-column {
	margin: 0 30px;
	flex-grow: 1;
}

.footer-content-column ul {
	list-style: none;
}

.footer-content-column ul li {
	margin: 10px 0;
}

.footer-content-column ul li a {
	color: #fff;
}

#main-scroll.loginsi .scroll-content #meow .container{
	flex-grow: 1;
}

#main-scroll.loginsi .scroll-content div:not([class]):not([style]) {
	margin-top: 50px;
	height: inherit;
}

h4#development-sign:hover {
  opacity: 1;
  color: #fff;
  cursor: pointer;
  background-color: #2F55FE;
	box-shadow: 0 .3rem .5rem rgba(0,0,0,.175) !important;
}

h4#development-sign {
	position: fixed;
	z-index: 999;
	top: 9px;
	width: 200px;
	height: 30px;
	opacity: 0.2;
	margin: 0 auto;
	font-size: 18px;
	padding-top: 4px;
	left: 0; right: 0;
	text-align: center;
	border-radius: 5px;
	transition: all 250ms;
	background-color: #5050507c;
}

h4#development-sign:hover #first-sign {
  opacity: 0;
}

h4#development-sign:hover #second-sign {
  opacity: 1;
}

#first-sign, #second-sign {
  left: 0; right: 0;
  position: absolute;
}

#second-sign {
  opacity: 0;
}

.scale-hide {
	-webkit-animation: scale-hide 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
			animation: scale-hide 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-in-center {
	-webkit-animation: scale-in-center 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in {
	-webkit-animation: slide-in-elliptic-top-fwd 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
	        animation: slide-in-elliptic-top-fwd 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
}

.slide-hide {
	-webkit-animation: slide-hide 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-hide 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
	0% {
	  -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
			  transform: translateY(-600px) rotateX(-30deg) scale(0);
	  -webkit-transform-origin: 50% 100%;
			  transform-origin: 50% 100%;
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateY(0) rotateX(0) scale(1);
			  transform: translateY(0) rotateX(0) scale(1);
	  -webkit-transform-origin: 50% 1400px;
			  transform-origin: 50% 1400px;
	  opacity: 1;
	}
  }
@keyframes slide-in-elliptic-top-fwd {
0% {
	-webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
			transform: translateY(-600px) rotateX(-30deg) scale(0);
	-webkit-transform-origin: 50% 100%;
			transform-origin: 50% 100%;
	opacity: 0;
}
100% {
	-webkit-transform: translateY(0) rotateX(0) scale(1);
			transform: translateY(0) rotateX(0) scale(1);
	-webkit-transform-origin: 50% 1400px;
			transform-origin: 50% 1400px;
	opacity: 1;
}
}

@-webkit-keyframes slide-hide {
	100% {
	  -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
			  transform: translateY(-600px) rotateX(-30deg) scale(0);
	  -webkit-transform-origin: 50% 100%;
			  transform-origin: 50% 100%;
	  opacity: 0;
	}
	0% {
	  -webkit-transform: translateY(0) rotateX(0) scale(1);
			  transform: translateY(0) rotateX(0) scale(1);
	  -webkit-transform-origin: 50% 1400px;
			  transform-origin: 50% 1400px;
	  opacity: 1;
	}
  }
@keyframes slide-hide {
100% {
	-webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
			transform: translateY(-600px) rotateX(-30deg) scale(0);
	-webkit-transform-origin: 50% 100%;
			transform-origin: 50% 100%;
	opacity: 0;
}
0% {
	-webkit-transform: translateY(0) rotateX(0) scale(1);
			transform: translateY(0) rotateX(0) scale(1);
	-webkit-transform-origin: 50% 1400px;
			transform-origin: 50% 1400px;
	opacity: 1;
}
}
  

@-webkit-keyframes scale-in-center {
	0% {
	  -webkit-transform: scale(0);
			  transform: scale(0);
	  opacity: 1;
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  opacity: 1;
	}
  }
  @keyframes scale-in-center {
	0% {
	  -webkit-transform: scale(0);
			  transform: scale(0);
	  opacity: 1;
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  opacity: 1;
	}
  }
  
@-webkit-keyframes scale-hide {
	0% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  opacity: 1;
	}
	100% {
	  -webkit-transform: scale(0);
			  transform: scale(0);
	  opacity: 1;
	}
  }
  @keyframes scale-hide {
	0% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  opacity: 1;
	}
	100% {
	  -webkit-transform: scale(0);
			  transform: scale(0);
	  opacity: 1;
	}
  }
  